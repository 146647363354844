import React, { useEffect, useState, useContext } from "react";

import { MatisAppContainer, ConverterContainer, ImgContainer } from "./matisapp.styles";

import UploadAndConvertFileToS3WithReactS3 from "../../components/s3Dropper/s3Dropper.component";

import { PRESIGNED_URL_API_ENDPOINT, CONVERSION_API_ENDPOINT } from '../../urlEndpoints';

import { userContext } from "../../contexts/userContext";

import AanvoerregisterHeader from '../../assets/images/imagesDashboard/MatisApp/AanvoerregisterHeader.png'
import AfvalstoffenregisterHeader from '../../assets/images/imagesDashboard/MatisApp/AfvalstoffenregisterHeader.png'
import OmzetKlantenVoorbeeld from '../../assets/images/imagesDashboard/MatisApp/OmzetKlantenVoorbeeld.png'
import hoeMatisAanvoerUpdatenPdf from '../../assets/pdfs/Hoe aanvoerregister updaten met nieuwe klantenlijst.pdf'

const MatisAppPage = () => {
  const { user } = useContext( userContext )
  
  console.log('MATISAPP PAGE COMPONENT: ', user)

  return (
    <MatisAppContainer>
    <h1>Matis Apps</h1>
      <ConverterContainer>
          <h2>Afvoerregister Converter</h2>
          {user ? 
            <UploadAndConvertFileToS3WithReactS3 
            apiKey={user.key}
            userName={user.username}
            appName={'matisAfvoer'}
            recipientMailAddress={user.email}
            CUSTOMERBUCKETNAME         = 'verhellebucket'
            UPLOADWRITEPATH            = 'afvoerregisters/in'
            PRESIGNED_URL_API_ENDPOINT = {PRESIGNED_URL_API_ENDPOINT}
            CONVERSION_API_ENDPOINT   = {CONVERSION_API_ENDPOINT}
            CONVERSION_READ_PATH       = 'afvoerregisters/in/'
            CONVERSION_WRITE_PATH       = 'afvoerregisters/out/'
            MULTIPLEFILES = {false}
            CONVERSION_NEEDED = {true}
            /> : null} 

          <div>
            <p>Zorg er zeker voor dat de header op de eerste lijn van de excel komt (met alle kolommen).</p>
            <p>Voorbeeld hieronder.</p>
            <ImgContainer src={AfvalstoffenregisterHeader} alt="Afvalstoffenregister header" />
          </div>
      </ConverterContainer>
        
        <br/>
        <br/>
      
      <ConverterContainer>
        <h2>Aanvoerregister Converter</h2>
          {user ? <UploadAndConvertFileToS3WithReactS3 
            apiKey={user.key}
            userName={user.username}
            appName={'matisAanvoer'}
            recipientMailAddress={user.email}
            CUSTOMERBUCKETNAME         = 'verhellebucket'
            UPLOADWRITEPATH            = 'aanvoerregisters/in'
            PRESIGNED_URL_API_ENDPOINT = {PRESIGNED_URL_API_ENDPOINT}
            CONVERSION_API_ENDPOINT    = {CONVERSION_API_ENDPOINT}
            CONVERSION_READ_PATH       = 'aanvoerregisters/in/'
            CONVERSION_WRITE_PATH       = 'aanvoerregisters/out/'
            MULTIPLEFILES = {false}
            CONVERSION_NEEDED = {true}
            /> : null}
            <div>
              <p>Zorg er zeker voor dat de header op de eerste lijn van de excel komt (met alle kolommen)</p>
              <p>Voorbeeld hieronder.</p>
              <ImgContainer src={AanvoerregisterHeader} alt="Aanvoerregister header" />
            </div>
        </ConverterContainer>
        
        <br/>
        <br/>

        <ConverterContainer>
        <h2>Genereer actuele klantenlijst voor update aanvoerregister</h2>
          {user ? <UploadAndConvertFileToS3WithReactS3 
            apiKey={user.key}
            userName={user.username}
            appName={'matisAanvoerUpdate'}
            recipientMailAddress={user.email}
            CUSTOMERBUCKETNAME         = 'verhellebucket'
            UPLOADWRITEPATH            = 'customerData/in'
            PRESIGNED_URL_API_ENDPOINT = {PRESIGNED_URL_API_ENDPOINT}
            CONVERSION_API_ENDPOINT    = {CONVERSION_API_ENDPOINT}
            CONVERSION_READ_PATH       = 'customerData/in/'
            CONVERSION_WRITE_PATH       = 'customerData/out/'
            MULTIPLEFILES = {false}
            CONVERSION_NEEDED = {true}
            /> : null}
            <div>
              <p>Upload een excel file met klanten omzet tot de huidige maand van het huidige jaar (zoals in voorbeeld hieronder maand september). Je zal manueel nog het aanvoerregister moeten updaten. How to vind je op deze PDF: <a href={hoeMatisAanvoerUpdatenPdf} download="Hoe aanvoerregister updaten met nieuwe klantenlijst">Hoe aanvoerregister updaten met nieuwe klantenlijst</a></p>
              <ImgContainer src={OmzetKlantenVoorbeeld} alt="voorbeeld omzet klanten" />
            </div>
        </ConverterContainer>

    </MatisAppContainer>
  );
};

export default MatisAppPage;
